import * as React from 'react';
import clsx from 'clsx';
import * as style from './Footer.module.scss';

export type Props = {
  backgroundColor?: string;
  children?: React.ReactNode;
  color?: string;
};

const INCLUDE_FOOTER_LEGAL = true;

export const Footer: React.FC<Props> = ({
  backgroundColor,
  children,
  color,
}) => (
  <footer className={clsx(style.wrapper)}>
    <div
      className={clsx(style.container, 'container container--fhd')}
      style={{ backgroundColor, color }}>
      <div className={clsx(style.siteInfo)}>
        {children}
        {INCLUDE_FOOTER_LEGAL ? (
          <>
            <br />
            <br />

            <p>
              * NO PURCHASE NECESSARY.{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://xr.maxresources.com/the-white-lotus-sweeps/form">
                Click to enter.
              </a>{' '}
              Open to legal residents of the 50 US and D.C., 18 years of age or
              older. Sweepstakes ends 11:59PM ET on 3/11/25. Void where
              prohibited. See{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://xr.maxresources.com/the-white-lotus-sweeps/form/rules.html">
                Official Rules
              </a>{' '}
              for details including employment/contractor requirements.
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  </footer>
);

Footer.defaultProps = {
  backgroundColor: '#000065',
  color: '#00f0ff',
  children: `©${new Date().getFullYear()} WarnerMedia Direct, LLC. All rights reserved. Max is used under license.`,
};
